import "./fonts/vulf-mono.ttf";

// console.log(window.location.host.split('.')[0]);

const isApp =
  window.location.href.includes("spaces") ||
  window.location.href.includes("pages") ||
  window.location.href.includes("reader") ||
  window.location.host.split(".")[0] === "playground";

const isDownload = window.location.href.includes("night");
const isMtime = window.location.href.includes("m-time");
const isTactile = window.location.href.includes("tactile");
const isForm = window.location.href.includes("forms")
const lastWord = window.location.href.split('/').pop();
let isAuto = false;
let isTabMaker = false;
if (lastWord === 'auto') {
  isAuto = true
}

if (lastWord === 'tabs') {
  isTabMaker = true
}

if (isApp) {
  Promise.all([
    import("react"),
    import("react-dom"),
    import("./index.css"),
    import("./App"),
    import("./serviceWorker"),
  ]).then(([React, ReactDOM, css, App, serviceWorker]) => {
    ReactDOM.render(
      <React.StrictMode>
        <App.default />
      </React.StrictMode>,
      document.getElementById("root")
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
} else if (isDownload) {
  window.location.href = "https://drive.proton.me/urls/GGT7TZJEW4#CY4d1z8WOg6Z";
} else if (isMtime) {
  window.location.href = "morphed-time.html";
} else if (isTactile) {
  window.location.href = "tactile.html";
} else if (isForm) {
  Promise.all([
    import("react"),
    import("react-dom"),
    import("./index.css"),
    import("./components/MyForm"),
  ]).then(([React, ReactDOM, css, { default: MyForm }]) => {
    ReactDOM.render(
      <>
        <MyForm />
      </>,
      document.getElementById("root")
    );
  });
 } else if (isAuto) {
   Promise.all([
     import("react"),
     import("react-dom"),
     import("./index.css"),
     import("./components/Auto"),
   ]).then(([React, ReactDOM, css, { default: Auto }]) => {
     ReactDOM.render(
       <>
         <Auto />
       </>,
       document.getElementById("root")
     );
   });
 } else if (isTabMaker) {
    window.location.href = "tabs.html";
} else {
  window.location.href = "intro.html";
}
